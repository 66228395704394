export const LIGHT_BLUE = '#ddfffe';
export const LIGHTER_BLUE = '#00fffe';
export const MEDIUM_BLUE = '#07a9da';
export const DARKER_BLUE = '#0d2275';
export const DARK_BLUE = '#0a1e51';

export const PURPLE = '#73349b'
export const ORANGE = '#ffa36a'
export const INDIGO = '#262262'

export const GRAY_1 = '#999999';
export const GRAY_2 = '#777777';
export const GRAY_3 = '#555555';
export const GRAY_4 = '#333333';
export const GRAY_5 = '#111111';
export const GRAY_6 = '#FCFAF9'

import React from 'react';

import Restaurant from './Restaurant';

const HuntersGrill = () => {

  const RESTAURANT_ID = 'H04R01'
  const content = ``

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default HuntersGrill

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function sortAlpha(a: string, b: string) {
  return a.localeCompare(b)
}

export default function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function validatePhone(phone_to_validate: string) {
  /*
  --------------------------------------------------------------------------------
    ^                        the beginning of the string
  --------------------------------------------------------------------------------
    \+                       '+'
  --------------------------------------------------------------------------------
    (?:                      group, but do not capture (between 6 and
                             14 times (matching the most amount
                             possible)):
  --------------------------------------------------------------------------------
      \d                       digits (0-9)
  --------------------------------------------------------------------------------
      \s?                      whitespace (\n, \r, \t, \f, and " ")
                               (optional (matching the most amount
                               possible))
  --------------------------------------------------------------------------------
    ){6,14}                  end of grouping
  --------------------------------------------------------------------------------
    \d                       digits (0-9)
  --------------------------------------------------------------------------------
    $                        before an optional \n, and the end of the
                             string
   */
  const re = /^\+(?:\d\s?){6,14}\d$/;
  return re.test(phone_to_validate);
}

export function uppercaseString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

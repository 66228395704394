import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


type dialogProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  dialogTitle: string
  dialogText: string
  okButtonText: string
  cancelButtonText: string
  handleOkClick: () => void
  handleCancelClick: () => void
}
export default function ConfirmationDialog({
                                             open,
                                             setOpen,
                                             dialogTitle,
                                             dialogText,
                                             okButtonText,
                                             cancelButtonText,
                                             handleOkClick,
                                             handleCancelClick,
                                           }: dialogProps) {

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    handleOkClick()
  }

  const handleCancel = () => {
    handleCancelClick()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">{cancelButtonText}</Button>
          <Button onClick={handleOk} variant="contained">{okButtonText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { IAlert } from '../interfaces'
import dayjs from 'dayjs'

const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN || ''
const API_DOMAIN = '/api/v1'

function getToken() {
  return localStorage.getItem('dlp_jwt')
}

export function removeToken() {
  localStorage.removeItem('dlp_jwt')
}

function fetchData(url: string, headers: any | null) {
  if (!headers) {
    headers = {}
  }

  return fetch(url, {headers: headers})
    .then(response => response.json())
}

function postData(url: string, data: any, headers: any) {
  if (!headers) {
    headers = {
      'Content-Type': 'application/json',
    }
  } else {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    }
  }

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
}

export function getRestaurants() {
  return fetchData(SERVER_DOMAIN + API_DOMAIN + '/restaurant/', null)
}

export function getRestaurant(restaurantId: string) {
  return fetchData(SERVER_DOMAIN + '/api/get-restaurant?restaurantId=' + restaurantId, null)
}

export function getReservations(showDisabled: boolean) {
  const token = getToken()
  let route = '/reservation/'
  if (showDisabled) {
    route += '?active=all'
  }
  return fetchData(SERVER_DOMAIN + API_DOMAIN + route,
    {Authorization: `Bearer ${token}`})
}

export function createAlert(alert: IAlert) {
  const token = getToken()
  const alertData = {
    date: dayjs(alert.date).format('YYYY-MM-DD'),
    num_guests: alert.guests,
    breakfast: alert.time === 'Breakfast',
    lunch: alert.time === 'Lunch',
    dinner: alert.time === 'Dinner',
    email: alert.email,
    phone: alert.phone,
    restaurant: alert.restaurantId,
    hotelGuest: alert.hotelGuest,
  }

  return postData(SERVER_DOMAIN + API_DOMAIN + '/create_reservation', alertData, {Authorization: `Bearer ${token}`})
}

export function login(email: string, password: string) {
  email = email.toLowerCase()
  return postData(SERVER_DOMAIN + '/api/token/', {email, password}, null)
}

export function signUp(email: string, password: string, language: string) {
  return postData(SERVER_DOMAIN + '/api/create-user/', {email, password, language}, null)
}

export function getUser() {
  const token = getToken()
  if (token) {
    return fetchData(SERVER_DOMAIN + '/api/get-user/', {Authorization: `Bearer ${token}`})
  } else {
    return false
  }
}

export async function checkUserExists(email: string) {
  const response = await postData(SERVER_DOMAIN + '/api/check-user-exists/', {email}, null)
  const data = await response.json()
  return {email: data.email, isLegacy: data.isLegacy}
}

export async function changeLegacyPassword(email: string, newPassword: string) {
  return postData(SERVER_DOMAIN + '/api/change-legacy-password/', {email, newPassword}, null)
}

export function getActiveReservationsRemaining() {
  const token = getToken()
  return fetchData(SERVER_DOMAIN + '/api/get-active-reservations-remaining/', {Authorization: `Bearer ${token}`})
}

export function getFreeReservationsRemaining() {
  const token = getToken()
  return fetchData(SERVER_DOMAIN + '/api/get-free-reservations-remaining/', {Authorization: `Bearer ${token}`})
}

export function postCheckout(itemKey: string, referralCode: string | null) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/create-checkout-session/', {
    itemKey,
    referralCode,
  }, {Authorization: `Bearer ${token}`})
}

export function postUpgradeSubscription() {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/upgrade-subscription/', {}, {Authorization: `Bearer ${token}`})
}

export function getManageSubscriptionURL() {
  const token = getToken()
  return fetchData(SERVER_DOMAIN + '/api/create-portal-session/', {Authorization: `Bearer ${token}`})
}

export function orderSuccess(sessionId: string, paymentProvider: string, referralCode: string | null, productId?: string) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/order-success/', {
    sessionId,
    paymentProvider,
    referralCode,
    productId,
  }, {Authorization: `Bearer ${token}`})
}

export function startReservation(reservationId: string) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/v1/start-reservation/', {reservationId}, {Authorization: `Bearer ${token}`})
}

export function stopReservation(reservationId: string) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/v1/stop-reservation/', {reservationId}, {Authorization: `Bearer ${token}`})
}

export function resetPassword(email: string) {
  return postData(SERVER_DOMAIN + '/api/reset-password/', {email}, null)
}

export function setPhoneNumber(phoneNumber: string) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/set-phone-number/', {phoneNumber}, {Authorization: `Bearer ${token}`})
}

export function getStripeClientSecret(reservationId: string) {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/get-stripe-client-secret/', {
    reservationId,
  }, {Authorization: `Bearer ${token}`})
}

export function cancelPayPalSubscription() {
  const token = getToken()
  return postData(SERVER_DOMAIN + '/api/cancel-paypal-subscription/', {}, {Authorization: `Bearer ${token}`})
}

import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { getReservations, getRestaurants, startReservation, stopReservation } from '../utils/api';
import { IReservation, IRestaurant } from '../interfaces';
import { GRAY_2, ORANGE, PURPLE } from '../styles';
import { MainContext } from './Main';

const Alerts = () => {
  const context = useContext(MainContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [reservations, setReservations] = useState<IReservation[]>([])
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [alertsLoading, setAlertsLoading] = useState<boolean>(false)
  const [alertChanging, setAlertChanging] = useState<boolean>(false)
  const [showDisabledChecked, setShowDisabledChecked] = useState<boolean>(false)

  useEffect(() => {

    const getRestaurantsAsync = async () => {
      const restaurantsResponse = await getRestaurants()
      setRestaurants(restaurantsResponse.results)
    }

    if (context.user) {
      getRestaurantsAsync()
      getReservationsAsync(false)
    } else {
      navigate('/login')
    }
  }, [context.user, navigate])

  const getReservationsAsync = async (showDisabled: boolean) => {
    const reservationsResponse = await getReservations(showDisabled)
    setReservations(reservationsResponse.results)
    setLoading(false)
  }

  const handleShowDisabledChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertsLoading(true)
    setShowDisabledChecked(event.target.checked)
    try {
      await getReservationsAsync(event.target.checked)
    } catch (e) {
      console.log(e)
    }
    setAlertsLoading(false)
  }

  type ReservationSummaryProps = {
    reservation: IReservation;
    restaurant: IRestaurant | undefined;
  }
  const ReservationSummary = ({reservation, restaurant}: ReservationSummaryProps) => {
    const onTurnAlertOn = async () => {
      setAlertChanging(true)
      try {
        const today = new Date(new Date().toDateString())
        const reservationDate = new Date(Date.parse(reservation.date))
        if (reservationDate >= today) {
          await startReservation(reservation.id)
          await getReservationsAsync(showDisabledChecked)
        }
      } catch (e) {
        console.log(e)
      }
      setAlertChanging(false)
    }

    const onTurnAlertOff = async () => {
      setAlertChanging(true)
      try {
        await stopReservation(reservation.id)
        await getReservationsAsync(showDisabledChecked)
      } catch (e) {
        console.log(e)
      }
      setAlertChanging(false)
    }

    return (
      <>
        <div className="reservation-container">
          <div className="reservation-title-container">
            <Typography variant="body2" className="bold">{restaurant?.name}</Typography>
            {reservation.active ? (
                <Tooltip title="Active">
                  <span>
                    <IconButton sx={{paddingTop: 0}} disabled={alertChanging} onClick={onTurnAlertOff}>
                      <NotificationsActiveIcon sx={{color: ORANGE}}/>
                    </IconButton>
                  </span>
                </Tooltip>) :
              <Tooltip title="Inactive">
                <span>
                  <IconButton sx={{paddingTop: 0}} disabled={alertChanging} onClick={onTurnAlertOn}>
                    <NotificationsOffIcon sx={{color: PURPLE}}/>
                  </IconButton>
                </span>
              </Tooltip>}
          </div>
          <div className="res-detail-container">
            <div>
              <Typography variant="body2">{new Date(Date.parse(reservation.date)).toLocaleDateString(undefined, {
                weekday: 'short',
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                timeZone: 'Europe/Paris',
              })}</Typography>
            </div>
            <div>
              {['breakfast', 'lunch', 'dinner'].map((meal) => {
                if (reservation[meal as keyof IReservation]) {
                  let mealtimeTranslated = ''
                  switch (meal) {
                    case 'breakfast':
                      mealtimeTranslated = t('breakfast', 'Breakfast')
                      break
                    case 'lunch':
                      mealtimeTranslated = t('lunch', 'Lunch')
                      break
                    case 'dinner':
                      mealtimeTranslated = t('dinner', 'Dinner')
                      break
                  }
                  return <Typography key={meal} variant="body2" className="light-text">
                    <span className="capitalize">{mealtimeTranslated}</span> {t('for', 'for')} {reservation.num_guests}
                  </Typography>
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>
        <Divider/>
      </>
    )
  }

  return (
    loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" width="60%" sx={{fontSize: '3rem'}}/>
          <Skeleton variant="rectangular" width="100%" height="20vh"/>
          <Skeleton variant="rectangular" width="100%" height="40vh"/>
        </Stack>) :
      (<Styled>
        <div className="alerts-container">
          <Typography variant="h3" className="alerts-title">{t('your-alerts', 'Your Alerts')}</Typography>
          <FormGroup>
            <FormControlLabel control={<Switch
              checked={showDisabledChecked}
              onChange={handleShowDisabledChange}
            />} label={t('show-disabled-alerts', 'Show disabled alerts')}/>
          </FormGroup>
          <Typography variant="body1" className="alerts-info">
            <Trans i18nKey={'click-to-disable-enable'}>
              Click <NotificationsActiveIcon sx={{color: ORANGE}}/> or <NotificationsOffIcon sx={{color: PURPLE}}/> to
              disable / enable alerts
            </Trans>
          </Typography>

          {/*Sort reservations*/}
          <Box sx={{flexGrow: 1}}>
            {alertsLoading ? <CircularProgress/> :
              reservations.length > 0 ? reservations.sort((a, b) => Date.parse(a.date) - Date.parse(b.date),
                ).map((reservation) => {
                  const reservationRestaurant = restaurants.find((restaurant) => restaurant.id === reservation.restaurant)
                  return (
                    <ReservationSummary key={reservation.id} reservation={reservation}
                                        restaurant={reservationRestaurant}/>)
                })
                : (showDisabledChecked ? <div>{t('no-alerts-disabled-visible', 'You have no alerts')}</div> :
                  <div>{t('no-alerts-enabled-only', 'No active alerts. Check your disabled alerts by clicking the above toggle.')}</div>)}
            <Typography variant="body2" className="alerts-disclaimer">
              {t('50-alerts-warning', 'Note: Only the most recent 50 alerts are shown')}
            </Typography>

          </Box>
        </div>
      </Styled>)
  )
}

const Styled = styled.div`

  .alerts-container {
    padding: 0 1rem;

    .alerts-title {
      padding-bottom: 2rem;
    }

    .alerts-info {
      padding: 1rem 0 2rem 0;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .light-text {
    color: ${GRAY_2}
  }

  .bold {
    font-weight: bold;
  }

  .reservation-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 80px;
    margin: 18px 0;
    column-gap: 16px;

    .reservation-title-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .res-detail-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

  }

  .alerts-disclaimer {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 600px) {
    .reservation-container {
      grid-template-columns: 1fr;

      .res-detail-container {
        margin-top: 6px;
      }
    }
  }
`

export default Alerts

import React from 'react';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { GRAY_4 } from '../styles';

type QuoteCardProps = {
  quoteText: string;
  customerName: string;
}
const QuoteCard = ({quoteText, customerName}: QuoteCardProps) => {
  return (
    <Card className="customer-quote-card">
      <div className="card-container">
        <Typography variant="h5" className="customer-quote">
          "{quoteText}"
        </Typography>
        <Typography variant="subtitle1" className="customer-name"> {customerName}</Typography>
      </div>
    </Card>
  )
}

const Quotes = () => {
  const {t} = useTranslation()

  return (
    <Styled>
      <div className="customer-quotes-container">
        <Typography variant="h6" gutterBottom>{t('user-quotes', 'User Quotes')}</Typography>
        <QuoteCard
          quoteText={t('quote-1', 'Managed to book Auberge de Cendrillon for christmas day because of your site! I tried for weeks with no luck, thank you so much!')}
          customerName="Ryan F."
        />
        <QuoteCard
          quoteText={t('quote-2', 'Tried for weeks to get Plaza Gardens for a friend\'s birthday, no luck. Set up an alert, got a table in two days. You rock, my dude.')}
          customerName="Clement P."
        />
        <QuoteCard
          quoteText={t('quote-3', 'We\'re off-site this time but thanks to your site, we got everything we wanted.')}
          customerName="Natalie E."
        />
        <QuoteCard
          quoteText={t('quote-4', 'I just want to thank you and say how amazing this service is. After signing up I have got both character dining at the Plaza and lunch with the princesses!')}
          customerName="Janine C."
        />
      </div>
    </Styled>
  )
}

const Styled = styled.div`

  .customer-quotes-container {
    max-width: 500px;
    margin-top: 5rem;
  }

  .customer-quote-card {
    margin-bottom: 2rem;
  }

  .card-container {
    margin: 1.5rem;
  }

  .customer-quote {
    font-style: italic;
    color: ${GRAY_4}
  }

  .customer-name {
    font-size: 1rem;
    font-style: italic;
  }
`

export default Quotes

import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// This page could have a table of restaurants showing price, character dining, which meal times open for etc.

const Restaurants = () => {
  const {t} = useTranslation()

  return (
    <Styled>
      <div className="introduction">
        <Typography variant="h5" gutterBottom>{t('restaurant-information', 'Restaurant Information')}</Typography>
        <Typography variant="body1"
                    paragraph>{t('restaurant-info2', 'Disneyland Paris has many fantastic themed restaurants to choose from.')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('restaurant-info3', `You can dine with Disney princesses in opulent surroundings, eat pirate grub inside
            the Pirates of the Caribbean ride, dine with Mickey,
            Minnie and friends and many more unique experiences.`)}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('restaurant-info-4', `The most popular restaurants book up months in advance, but our alerts
            will help you get the Disneyland Paris restaurant reservations you're looking for!`)}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('restaurant-info-5', `Find information on the most popular restaurants below:`)}
        </Typography>

        <ul>
          <li className="restaurant-list-item"><Link to="auberge-de-cendrillon">Auberge de Cendrillon</Link></li>
          <li className="restaurant-list-item"><Link to="bistrot-chez-remy">Bistrot Chez Rémy</Link></li>
          <li className="restaurant-list-item"><Link to="captain-jacks">Captain Jack's - Restaurant des Pirates</Link>
          </li>
          <li className="restaurant-list-item"><Link to="character-dining-at-plaza-gardens">Character Dining at Plaza
            Gardens</Link></li>
          <li className="restaurant-list-item"><Link to="downtown-restaurant">Downtown Restaurant</Link></li>
          <li className="restaurant-list-item"><Link to="hunters-grill">Hunter's Grill and Beaver Creek Tavern</Link>
          </li>
          <li className="restaurant-list-item"><Link to="la-grange-billy-bobs">La Grange at Billy Bob’s Country Western
            Saloon</Link></li>
          <li className="restaurant-list-item"><Link to="manhattan-restaurant">Manhattan Restaurant</Link></li>
          <li className="restaurant-list-item"><Link to="plaza-gardens-restaurant">Plaza Gardens Restaurant (no
            characters)</Link></li>
          <li className="restaurant-list-item"><Link to="pym-kitchen">PYM Kitchen</Link></li>
          <li className="restaurant-list-item"><Link to="restaurant-agrabah-cafe">Restaurant Agrabah Café</Link></li>
          <li className="restaurant-list-item"><Link to="royal-banquet">Royal Banquet</Link></li>
          <li className="restaurant-list-item"><Link to="the-steakhouse">The Steakhouse</Link></li>
          <li className="restaurant-list-item"><Link to="walts-restaurant">Walt's - an American restaurant</Link></li>
        </ul>

      </div>

    </Styled>
  )
}

const Styled = styled.div`

    .introduction {
        padding: 1rem 0 0 0;
        text-align: left;

    }

    .restaurant-list-item {
        margin-bottom: 1rem;

    }

`

export default Restaurants

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { IRestaurant } from '../../interfaces';
import { getRestaurant } from '../../utils/api';
import Loading from '../Loading';
import { GRAY_3, GRAY_6, INDIGO } from '../../styles';
import { Button } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom';


type RestaurantProps = {
  restaurantId: string,
  content: string,
}
const Restaurant = ({restaurantId, content}: RestaurantProps) => {
  const [restaurant, setRestaurant] = useState<IRestaurant | null>(null)
  const navigate = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const res = await getRestaurant(restaurantId)
        setRestaurant(res)
      } catch (error) {
        console.error(error)
      }
    }
    fetchRestaurant()
  }, [restaurantId])

  const onCreateAlertClick = () => {
    navigate('/')
  }

  return restaurant?.name ? (
    <Styled>
      <div className="introduction">
        <Typography variant="body1" paragraph><Link to="/restaurants">{t('back', 'Back')}</Link></Typography>
        <Typography variant="h5" gutterBottom>{restaurant.name}</Typography>
        <div className="picture-container" key={restaurant.id}>
          <div className="tables-found-container">
            {restaurant.tables_found_count?.toLocaleString()} {t('tables-found', 'tables found!')}
          </div>
          <img className="restaurant-image"
               src={restaurant.image_url}
               alt={restaurant.name}/>
        </div>
        <Typography variant={'h6'}>{t('price', 'Price')}</Typography>
        <Typography variant="body1" paragraph>{restaurant.price}</Typography>

        <Typography variant={'h6'} className="paragraph-gap">{t('latest-dates', 'Latest bookable dates')}
          <Tooltip
            title={t('latest-dates-description', `These are the latest dates you can make a reservation for in the Disneyland Paris app. Disney
              releases more dates regularly. Set up an alert to be notified when your preferred date is available.`) as string}
            enterTouchDelay={0} leaveTouchDelay={8000}>
            <Button sx={{minWidth: 0, verticalAlign: 'bottom'}}>
              <HelpOutlineIcon className="bookable-dates-help-icon"
                               sx={{
                                 fontSize: 20,
                                 color: GRAY_3,
                               }}/>
            </Button>
          </Tooltip>
        </Typography>
        <Typography variant="body1">{`${t('non-hotel-guests', 'Non-hotel guests:')} 
          ${new Date(Date.parse(restaurant.latest_bookable_date)).toLocaleDateString(undefined, {
          month: 'short',
          year: 'numeric',
          day: 'numeric',
          timeZone: 'Europe/Paris',
        })}`}</Typography>
        <Typography variant="body1" paragraph>{`${t('hotel-guests', 'Hotel guests:')} 
          check Disneyland Paris app`}</Typography>

        <Typography variant={'h6'}
                    className="paragraph-gap">{t('bookable-mealtimes', 'Bookable mealtimes')}</Typography>
        <Typography
          variant="body1">{t('breakfast', 'Breakfast')}: {restaurant.breakfast ? t('yes', 'Yes') : t('no', 'No')}</Typography>
        <Typography
          variant="body1">{t('lunch', 'Lunch')}: {restaurant.lunch ? t('yes', 'Yes') : t('no', 'No')}</Typography>
        <Typography
          variant="body1">{t('dinner', 'Dinner')}: {restaurant.dinner ? t('yes', 'Yes') : t('no', 'No')}</Typography>

        <Typography variant={'h6'}
                    className="paragraph-gap">{t('official-description', 'Official description')}</Typography>
        <Typography variant="body1" paragraph>{restaurant.short_description}</Typography>

        {content && (<><Typography variant={'h6'}
                                   className="paragraph-gap">{t('dlptables-description', 'dlpTables description')}</Typography>
          <Typography variant="body1" paragraph className="display-linebreak">{content}</Typography></>)}

        <Typography variant={'h6'} className="paragraph-gap">{t('plans-accepted', 'Dining plans accepted')}</Typography>
        <Typography variant="body1" paragraph>{restaurant.dining_plans}</Typography>

        {restaurant.menu_url && (<><Typography variant={'h6'} className="paragraph-gap">{t('menu', 'Menu')}</Typography>
          <Typography variant="body1" paragraph>
            <a href={restaurant.menu_url} target="_blank"
               rel="noopener noreferrer">
              {t('download-pdf', 'Download PDF')}
            </a>
          </Typography></>)}

        <Typography variant={'h6'} className="paragraph-gap">{t('official-page', 'Official Page')}</Typography>
        <Typography variant="body1" paragraph>
          <a href={restaurant.restaurant_url} target="_blank"
             rel="noopener noreferrer">
            {t('click-here', 'Click here')}
          </a></Typography>

        <div className="alert-button-container">
          <Button className="create-alert-button" variant="contained"
                  onClick={onCreateAlertClick}>{t('create-alert', 'Create Alert')}</Button>
        </div>

      </div>

    </Styled>
  ) : <Loading/>
}

const Styled = styled.div`

  .introduction {
    padding: 1rem 0 0 0;
    text-align: left;

  }

  .picture-container {
    margin-bottom: 2rem;

    .restaurant-image {
      width: 100%;
      max-width: 500px;
    }

    .tables-found-container {
      position: absolute;
      margin: 0.8rem 0 0 0.8rem;
      padding: 0.5rem;
      background-color: ${INDIGO};
      color: ${GRAY_6};
      border-radius: 4px;
    }
  }

  .paragraph-gap {
    margin-top: 2rem;
  }

  .display-linebreak {
    white-space: pre-line;
  }

  .alert-button-container {
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: center;

    .create-alert-button {
      margin-top: 3rem;
    }
  }

`

export default Restaurant

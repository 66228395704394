import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { IRestaurant } from '../interfaces';
import { INDIGO, GRAY_6 } from '../styles';
import { Link } from 'react-router-dom';

type TopRestaurantsProps = {
  restaurants: IRestaurant[]
}
const TopRestaurants = ({restaurants}: TopRestaurantsProps) => {
  const {t} = useTranslation()

  return (
    <Styled>
      <div className="top-restaurants-container">
        <Typography variant="h6" gutterBottom>{t('popular-restaurants', 'Popular Restaurants')}</Typography>
        <div className="pictures-container">
          {restaurants?.sort((a, b) => b.tables_found_count - a.tables_found_count)
            .slice(0, 7)
            .map(restaurant => {
              return (
                <div className="picture-container" key={restaurant.id}>
                  <Link to={'restaurants/' + restaurant.dlp_restaurant_url}>
                    <div className="tables-found-container">
                      {restaurant.tables_found_count?.toLocaleString()} {t('tables-found', 'tables found!')}
                    </div>
                    <img className="restaurant-image"
                         src={restaurant.image_url}
                         alt={restaurant.name}
                    />
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>{restaurant.name}</Typography>
                  </Link>
                </div>)
            })}
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`

  .top-restaurants-container {
    padding: 3rem 0 0 0;
    text-align: left;

    .picture-container {
      margin-bottom: 2rem;

      .restaurant-image {
        width: 100%;
        max-width: 500px;
      }

      .tables-found-container {
        position: absolute;
        margin: 0.8rem 0 0 0.8rem;
        padding: 0.5rem;
        background-color: ${INDIGO};
        color: ${GRAY_6};
        border-radius: 4px;
      }
    }
  }
`

export default TopRestaurants

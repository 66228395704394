import { useRouteError } from 'react-router-dom';
import styled from '@emotion/styled';
import Header from './components/Header';

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Header onMenuClick={() => null}/>
      <Styled>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </Styled>
    </div>
  );
}

const Styled = styled.div`
  padding: 7rem 1rem;
`

import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';

const MainSkeleton = () => {

  return (
    <Styled>
      <Stack spacing={1}>
        <Skeleton variant="text" width="100%" sx={{fontSize: '3rem', maxWidth: '500px'}}/>
        <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
        <div className="two-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="435px"
                  sx={{maxWidth: '500px'}}/>
        <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
        <div className="two-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="275px" sx={{maxWidth: '500px'}}/>
        <div className="two-rem-gap"/>

        <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
        {[1, 2, 3, 4, 5, 6, 7].map(x => (
          <div key={x}>
            <Skeleton variant="rectangular" width="100%" height="275px" sx={{maxWidth: '500px'}}/>
            <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
            <div className="one-rem-gap"/>
          </div>
        ))}

        <div className="two-rem-gap"/>
        <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
        <Skeleton variant="rectangular" width="100%" height="160px" sx={{maxWidth: '500px'}}/>
        <div className="one-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="180px" sx={{maxWidth: '500px'}}/>

        <div className="two-rem-gap"/>
        <Skeleton variant="text" width="100%" sx={{fontSize: '2rem', maxWidth: '500px'}}/>
        <Skeleton variant="rectangular" width="100%" height="200px" sx={{maxWidth: '500px'}}/>
        <div className="one-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="200px" sx={{maxWidth: '500px'}}/>
        <div className="one-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="140px" sx={{maxWidth: '500px'}}/>
        <div className="one-rem-gap"/>
        <Skeleton variant="rectangular" width="100%" height="200px" sx={{maxWidth: '500px'}}/>
        <div className="one-rem-gap"/>

      </Stack>
    </Styled>
  )
}

const Styled = styled.div`

  .two-rem-gap {
    height: 1.7rem;
  }

  .one-rem-gap {
    height: 0.6rem;
  }
`

export default MainSkeleton

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AlertForm from './AlertForm';
import TopRestaurants from './TopRestaurants';
import Quotes from './Quotes';
import { getRestaurants } from '../utils/api';
import { IRestaurant } from '../interfaces';
import HowItWorks from './HowItWorks';
import BecomeMemberCard from './BecomeMemberCard';
import MainSkeleton from './MainSkeleton';

const Home = () => {
  const {t} = useTranslation();
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const res = await getRestaurants()
        setRestaurants(res.results)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchRestaurants()
  }, [])

  return (
    <Styled>
      {loading ? <MainSkeleton/> :
        (<>
          <div className="introduction">
            <Typography variant="h4"
                        gutterBottom>{t('find-dlp-reservations', 'Find Disneyland Paris restaurant reservations!')}</Typography>
            <Typography variant="body1">
              {t('get-instant-alerts', 'Get email and text* alerts when tables come available at Disneyland Paris restaurants')}
            </Typography>
          </div>
          <AlertForm restaurants={restaurants}/>
          <Typography variant="body2" className="disclaimer">
            {t('unlimited-for-members-only', '* Text/sms and priority alerts for paid memberships only.')}
            {' '}
            <Link
              to="/pricing">
              {t('see-pricing', 'See pricing')}
            </Link></Typography>
          <BecomeMemberCard/>
          <TopRestaurants restaurants={restaurants}/>
          <HowItWorks/>
          <Quotes/>
        </>)
      }
    </Styled>
  )
}

const Styled = styled.div`

    .introduction {
        padding: 1rem 0 0 0;
        text-align: left;

        .directions-list {
            li {
                margin-bottom: 1rem;
            }
        }
    }

    .disclaimer {
        margin-top: 1rem;
    }

`

export default Home

import React from 'react'
import Card from '@mui/material/Card';
import { CardActions, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';


const BecomeMemberCard = () => {
  const {t} = useTranslation()

  return (
    <Styled>
      <Card className="member-card">
        <CardContent>
          <Typography variant={'h5'} className="margin-bottom">
            {t('want-up-to', 'Want up to 20 active email and/or SMS alerts?')}
          </Typography>
          <Typography variant={'body1'} paragraph>
            {t('become-member-today', 'Become a member today!')}
          </Typography>
          <Typography variant={'body1'}>
            {t('from-just-10', 'From just €10 per month. Cancel anytime.')}
          </Typography>
        </CardContent>
        <CardActions className={'member-card-actions'}>
          <Link to={'/pricing'}>
            <Button variant={'contained'}
                    className="member-card-button">{t('become-member', 'Become a member')}</Button>
          </Link>
        </CardActions>
      </Card>
    </Styled>
  )
}

const Styled = styled.div`
  margin-top: 50px;

  .member-card {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    row-gap: 12px;
    max-width: 500px;
    margin: 3rem 0 0 0;
    padding: 1rem;
  }

  .member-card-button {
    width: 100%;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

  .member-card-actions {
    display: flex;
    justify-content: center;
  }
`

export default BecomeMemberCard

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    whitelist: ['en', 'fr'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    debug: process.env.NODE_ENV === "development",
    keySeparator: false,
    nsSeparator: "|",

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: process.env.NODE_ENV === "development" ? '/locales/{{lng}}/translation.json' : '/static/locales/{{lng}}/translation.json',
    }
  });

export default i18n;

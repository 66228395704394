import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
  AlertTitle,
  Snackbar,
  FormControlLabel, Checkbox, SelectChangeEvent,
} from '@mui/material';
import Card from '@mui/material/Card';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import {
  createAlert,
  checkUserExists,
  signUp,
  login,
  changeLegacyPassword,
  getActiveReservationsRemaining,
  getFreeReservationsRemaining,
} from '../utils/api';
import { IRestaurant, ISnackbarContent, ITime } from '../interfaces';
import { sortAlpha, uppercaseString, validatePhone } from '../utils/helpers';
import { MainContext } from './Main';
import { GRAY_3 } from '../styles';
import PaymentForm from './PaymentForm';
import Loading from './Loading';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type AlertFormProps = {
  restaurants: IRestaurant[]
}
const AlertForm = ({restaurants}: AlertFormProps) => {
  const context = useContext(MainContext)
  const {t, i18n} = useTranslation()
  const [selectedRestaurant, setSelectedRestaurant] = useState<IRestaurant | null>(null)
  const [reservationDate, setReservationDate] = useState<Date | null>(new Date())
  const [selectedTime, setSelectedTime] = useState<ITime | ''>('')
  const [numOfGuests, setNumOfGuests] = useState<Number>(2)
  const [email, setEmail] = useState<string>(context?.user?.email || '')
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true)
  const [validationEmailText, setValidationEmailText] = useState<string>('')
  const [validatedRestaurant, setValidatedRestaurant] = useState<boolean>(true)
  const [validatedTime, setValidatedTime] = useState<boolean>(true)
  const [hotelGuestChecked, setHotelGuestChecked] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const [validationPhoneText, setValidationPhoneText] = useState<string>('')
  const [validatedPhone, setValidatedPhone] = useState<boolean>(true)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [validatedPassword, setValidatedPassword] = useState<boolean>(true)
  const [validatedConfirmPassword, setValidatedConfirmPassword] = useState<boolean>(true)
  const [validationConfirmPasswordText, setValidationConfirmPasswordText] = useState<string>('')
  const [loginUser, setLoginUser] = useState<boolean>(false)
  const [signupUser, setSignupUser] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [freeReservationsRemaining, setFreeReservationsRemaining] = useState<number>(1)
  const [activeReservationsRemaining, setActiveReservationsRemaining] = useState<number>(0)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarContent, setSnackbarContent] = useState<ISnackbarContent>({severity: 'success', title: '', body: ''})
  const [payDialogOpen, setPayDialogOpen] = useState<boolean>(false)
  const [reservationId, setReservationId] = useState<string | null>(null)

  useEffect(() => {
    if (context.user?.email) {
      onGetFreeReservationsRemaining()
      onGetActiveReservationsRemaining()
    } else {
      setLoading(false)
    }
  }, [context.user])

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (clientSecret) {
      setPayDialogOpen(true)
    }
  }, [])

  const onGetActiveReservationsRemaining = async () => {
    try {
      const response = await getActiveReservationsRemaining()
      setActiveReservationsRemaining(response.user.activeReservationsRemaining)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onGetFreeReservationsRemaining = async () => {
    try {
      const response = await getFreeReservationsRemaining()
      setFreeReservationsRemaining(response.user.freeReservationsRemaining)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  function validateEmail(email_to_validate: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email_to_validate);
  }

  const callCreateAlert = async () => {
    try {
      const userEmail = email ? email : context.user.email
      const response = await createAlert({
        restaurantId: selectedRestaurant?.id || '',
        date: reservationDate as Date,
        time: selectedTime as ITime,
        guests: numOfGuests,
        email: userEmail,
        phone: phone,
        hotelGuest: hotelGuestChecked,
      })
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const payload = await response.json()
      if (!payload.id) {
        // Create alert was unsuccessful
        setSnackbarContent({
          severity: 'error',
          title: t('create-alert-failed', 'Create alert failed ☹️'),
          body: payload.message,
        })
        setShowSnackbar(true)
        if (payload.message === 'Phone number invalid') {
          setValidatedPhone(false)
          setValidationPhoneText(payload.message)
        }
      } else {
        if (payload.status === 'pending_payment' || (freeReservationsRemaining < 1 && !context.user?.activeSubscriber)) {
          setReservationId(payload.id)
          setPayDialogOpen(true)
        } else if (payload.status === 'inactive') {
          // User has used all of their available active reservations
          setSnackbarContent({
            severity: 'error',
            title: t('create-alert-failed', 'Create alert failed ☹️'),
            body: t('active-alert-quota-met', 'Members can have a maximum of 20 active alerts. Please deactivate an alert on the "Your Alerts" page to create a new one.'),
          })
          setShowSnackbar(true)
        } else {
          let body = ''
          if (context.user.activeSubscriber) {
            body = t('member-confirm-alert', 'We\'ll let you know when we find a table.\n\n')
          } else {
            body = t('non-member-confirm-alert', 'We\'ll let you know when we find a table.\n\n')
          }
          setSnackbarContent({
            severity: 'success',
            title: t('alert-created', 'Alert created!'),
            body: body,
          })
          setShowSnackbar(true)
        }

        setSelectedRestaurant(null)
        setSelectedTime('')
        setPassword('')
        setConfirmPassword('')
        setValidationConfirmPasswordText('')
        setLoginUser(false)
        setSignupUser(false)
        await onGetActiveReservationsRemaining()
        await onGetFreeReservationsRemaining()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const callLoginUser = async () => {
    try {
      const response = await login(
        email,
        password,
      )
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const data = await response.json()
      localStorage.setItem('dlp_jwt', data.access)
      // This is our flag to indicate devices that have already signed up. We check it to avoid the same device
      // registering multiple accounts.
      localStorage.setItem('locale_set', 'true')
      await context.refreshUser()
    } catch (e) {
      console.log(e)
      setValidatedPassword(false)
    }
  }

  const onCreateAlert = async () => {
    setLoading(true)
    setValidatedEmail(true)
    setValidationEmailText('')
    setValidatedPhone(true)
    setValidationPhoneText('')
    setValidatedRestaurant(true)
    setValidatedTime(true)
    setValidatedPassword(true)
    setValidatedConfirmPassword(true)
    setValidationConfirmPasswordText('')

    let fieldsValidated = true

    if (!validateEmail(email)) {
      setValidatedEmail(false)
      setValidationEmailText(t('valid-email-required', 'Valid email required') as string)
      fieldsValidated = false
    }

    // Check if this device has signed-up with a different user
    if (localStorage.getItem('locale_set') === 'true') {
      setValidatedEmail(false)
      setValidationEmailText(t('already-registered', 'It looks like you have already registered with a different email address. Please consider becoming a member.') as string)
    }

    if (context?.user?.activeSMSSubscriber && !context?.user?.hasPhoneNum && !validatePhone(phone)) {
      setValidatedPhone(false)
      setValidationPhoneText(t('phone-warning', 'Number should include the country code, start with a + and be followed only by numbers') as string)
      fieldsValidated = false
    }

    if (!selectedRestaurant) {
      setValidatedRestaurant(false)
      fieldsValidated = false
    }

    if (!selectedTime) {
      setValidatedTime(false)
      fieldsValidated = false
    }

    if (fieldsValidated && selectedRestaurant && reservationDate && selectedTime && numOfGuests) {

      if (context.user) {
        await callCreateAlert()
      } else {
        // sign-up flow
        // check if email is registered. If so, send to login
        // if new user, prompt to create password
        const userExistsData = await checkUserExists(email)
        if (userExistsData.email && !userExistsData.isLegacy) {
          // If email is registered, prompt for password
          // show password input
          setLoginUser(true)
          setSignupUser(false)
          if (password.length > 0) {
            // log user in
            await callLoginUser()
          }

          // create-reservation
          await callCreateAlert()

        } else {
          // Show create password inputs
          setSignupUser(true)
          setLoginUser(false)

          // create user
          if (signupUser && password.length < 7) {
            setValidatedPassword(false)
            setLoading(false)
            return false
          } else if (signupUser && password.length > 0 && password !== confirmPassword) {
            setValidatedConfirmPassword(false)
            setValidationConfirmPasswordText(t('passwords-no-match', 'Passwords do not match') as string)
            setLoading(false)
            return false
          }

          if (signupUser && password.length > 0) {
            try {
              if (userExistsData.email && userExistsData.isLegacy) {
                // Legacy user that exists but did not set a password
                const changePwResponse = await changeLegacyPassword(email, password)
                if (!changePwResponse.ok) {
                  throw new Error(changePwResponse.statusText)
                }

              } else {
                // Not a legacy user, regular sign up
                const language = i18n.resolvedLanguage
                const signUpResponse = await signUp(
                  email,
                  password,
                  language,
                )
                if (!signUpResponse.ok) {
                  throw new Error(await signUpResponse.json())
                }
              }
            } catch (e: any) {
              setValidatedPassword(false)
              setValidatedConfirmPassword(false)
              setValidationConfirmPasswordText(e.message)
              setLoading(false)
              return false
            }

            await callLoginUser()
            setSignupUser(false)

            // create reservation
            await callCreateAlert()
          }
        }
      }
    }

    setLoading(false)
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  }

  const ReservationsRemaining = () => {
    if (context.user?.activeSubscriber) {
      if (loading) {
        return <Loading/>
      } else {
        return (activeReservationsRemaining < 5 ?
          (<div className="reservations-remaining-container">
            <Typography variant="body2">
              <Trans i18nKey="active-reservations-remaining" count={Math.max(activeReservationsRemaining, 0)}>
            <span className={activeReservationsRemaining < 1 ? 'red-text' : ''}>
              {{count: Math.max(activeReservationsRemaining, 0)} as any}</span> active alerts remaining
              </Trans>
            </Typography>
          </div>) :
          null)
      }

    } else {
      return (
        !context.user?.activeSubscriber && context.user?.email && (!loading ?
          (<div className="reservations-remaining-container">
            <Typography variant="body2">
              <Trans i18nKey="free-reservations-remaining" count={Math.max(freeReservationsRemaining, 0)}>
            <span className={freeReservationsRemaining < 1 ? 'red-text' : ''}>
              {{count: Math.max(freeReservationsRemaining, 0)} as any}</span> free alerts remaining
              </Trans>
            </Typography>
            {
              freeReservationsRemaining < 1 ?
                <Link to="/pricing" className="margin-left"><Typography variant="body2">
                  {t('see-payment-options', 'See payment options')}
                </Typography></Link> :
                <Link to="/pricing"><HelpOutlineIcon className="reservation-help-icon" sx={{fontSize: 18}}/></Link>
            }
          </div>) :
          <Loading/>)
      )
    }
  }

  const onChangeRestaurant = (event: SelectChangeEvent) => {
    setSelectedRestaurant(restaurants.find(restaurant => restaurant.id === event.target.value) ?? null)
    setSelectedTime('')
  }

  return (
    <Styled>
      <Card className="alert-card">
        <FormControl fullWidth>
          <InputLabel>{t('restaurant', 'Restaurant')}</InputLabel>
          <Select required error={!validatedRestaurant} value={selectedRestaurant?.id ?? ''}
                  label={t('restaurant', 'Restaurant')}
                  onChange={onChangeRestaurant}>
            {restaurants.sort((a, b) => sortAlpha(a.name, b.name)).map(restaurant => (
              <MenuItem key={restaurant.id} value={restaurant.id}>{restaurant.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label={t('reservation-date', 'Reservation Date')}
              inputFormat="DD/MM/YYYY"
              value={reservationDate}
              disablePast
              onChange={value => {
                setReservationDate(value)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl>
          <InputLabel>{t('time', 'Time')}</InputLabel>
          <Select required error={!validatedTime} value={selectedTime}
                  onChange={event => setSelectedTime(event.target.value as ITime)} label="Time">
            {selectedRestaurant ?
              ['breakfast', 'lunch', 'dinner'].map(mealtime => {
                if (selectedRestaurant[mealtime as 'breakfast' | 'lunch' | 'dinner']) {
                  let mealtimeTranslated = ''
                  switch (mealtime) {
                    case 'breakfast':
                      mealtimeTranslated = t('breakfast', 'Breakfast')
                      break
                    case 'lunch':
                      mealtimeTranslated = t('lunch', 'Lunch')
                      break
                    case 'dinner':
                      mealtimeTranslated = t('dinner', 'Dinner')
                      break
                  }
                  const upperMealtime = uppercaseString(mealtime)
                  return (<MenuItem key={upperMealtime} value={upperMealtime}>{mealtimeTranslated}</MenuItem>)
                } else {
                  return null
                }
              })
              : [<MenuItem key="Breakfast" value="Breakfast">{t('breakfast', 'Breakfast')}</MenuItem>,
                <MenuItem key="Lunch" value="Lunch">{t('lunch', 'Lunch')}</MenuItem>,
                <MenuItem key="Dinner" value="Dinner">{t('dinner', 'Dinner')}</MenuItem>]}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>{t('guests', 'Guests')}</InputLabel>
          <Select value={numOfGuests} onChange={event => setNumOfGuests(event.target.value as Number)}
                  label={t('guests', 'Guests')}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(val => (<MenuItem key={val} value={val}>{val}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl className="form-control-hotel">
          <FormControlLabel control={<Checkbox checked={hotelGuestChecked}
                                               onChange={event => setHotelGuestChecked(event.target.checked)}/>}
                            label={t('staying-in-disney', 'Staying in a Disney Hotel')} sx={{marginRight: '0px'}}/>
        </FormControl>
        {hotelGuestChecked &&
          <Typography variant="body2" className="hotel-warning">
            {t('hotel-reservation-warning', 'Note: Hotel guests get early access to reservations, but unfortunately we can only notify you of dates up to 60 days in advance.') as string}
            {' '}
            <Link
              to="/faq">
              {t('see-faq', 'See FAQ')}
            </Link></Typography>
        }
        {!context.user && <FormControl>
          <TextField required error={!validatedEmail} helperText={validationEmailText} label={t('email', 'Email')}
                     type="email"
                     autoComplete="email"
                     value={email ?? ''}
                     onChange={event => setEmail(event.target.value)}/>
        </FormControl>}
        {context?.user?.activeSMSSubscriber && !context?.user?.hasPhoneNum && <FormControl>
          <TextField required error={!validatedPhone} helperText={validationPhoneText} label={t('phone', 'Phone')}
                     type="tel"
                     autoComplete="tel"
                     placeholder={t('example-phone-num', 'e.g. +44 787 025 8784') as string}
                     value={phone ?? ''}
                     onChange={event => setPhone(event.target.value)}/>
        </FormControl>}
        {(loginUser || signupUser) && (<FormControl>
          <TextField required error={!validatedPassword}
                     helperText={loginUser ? t('email-found', 'Email found. Please enter your password') : t('enter-password', 'Enter password to create an account. Minimum 7 characters')}
                     label={t('password', 'Password')}
                     type="password"
                     autoComplete={loginUser ? 'current-password' : 'new-password'}
                     value={password}
                     onChange={event => setPassword(event.target.value)}/>
        </FormControl>)}
        {signupUser && (<FormControl>
          <TextField required error={!validatedConfirmPassword} helperText={validationConfirmPasswordText}
                     label={t('confirm-password', 'Confirm Password')}
                     type="password"
                     autoComplete="new-password"
                     value={confirmPassword}
                     onChange={event => setConfirmPassword(event.target.value)}/>
        </FormControl>)}
        <LoadingButton loading={loading} variant="contained" onClick={onCreateAlert}>
          {t('create-alert', 'Create Alert')}
        </LoadingButton>
        <ReservationsRemaining/>
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarContent.severity} sx={{width: '100%'}}>
            <AlertTitle>{snackbarContent.title}</AlertTitle>
            {snackbarContent.body}
          </Alert>
        </Snackbar>
      </Card>
      <Dialog open={payDialogOpen} onClose={() => setPayDialogOpen(false)} className="pay-dialog-container">
        <DialogContent>
          <PaymentForm setPayDialogOpen={setPayDialogOpen} reservationId={reservationId}/>
        </DialogContent>
      </Dialog>
    </Styled>
  )
}

const Styled = styled.div`

    .alert-card {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
        row-gap: 12px;
        max-width: 500px;
        margin: 3rem 0 0 0;
        padding: 1rem;
    }

    .red-text {
        color: red;
    }

    .form-control-hotel {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .hotel-warning {
        margin-top: 0rem;
    }

    .reservations-remaining-container {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .margin-left {
            margin-left: 4px;
        }

        .reservation-help-icon {
            margin-left: 4px;
            color: ${GRAY_3}
        }

        .pay-dialog-container {
            min-width: 300px;
            min-height: 370px;
        }
    }
`

export default AlertForm

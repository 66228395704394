import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Alert, FormControl, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { login } from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';

import { MainContext } from './Main';

const LoginComponent = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true)
  const [validationEmailText, setValidationEmailText] = useState<string>('')
  const [validatedPassword, setValidatedPassword] = useState<boolean>(true)
  const [validationPasswordText, setValidationPasswordText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [invalidLogin, setInvalidLogin] = useState<boolean>(false)
  const navigate = useNavigate();
  const context = useContext(MainContext)
  const {t} = useTranslation()

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onLogin = async () => {
    setLoading(true)
    setValidatedEmail(true)
    setValidationEmailText('')
    setValidatedPassword(true)
    setValidationPasswordText('')
    setInvalidLogin(false)
    let emailIsValid = true

    if (password.length < 1) {
      setValidatedPassword(false)
      setValidationPasswordText(t('password-required', 'Password required') as string)
    }

    if (!validateEmail(email)) {
      setValidatedEmail(false)
      emailIsValid = false
      setValidationEmailText(t('valid-email-required', 'Valid email required') as string)
    }

    if (emailIsValid) {
      try {
        const response = await login(
          email,
          password,
        )
        if (response.status !== 200) {
          setValidatedEmail(false)
          setValidatedPassword(false)
          setInvalidLogin(true)
        } else {
          const data = await response.json()
          localStorage.setItem('dlp_jwt', data.access)
          // This is our flag to indicate devices that have already signed up. We check it to avoid the same device
          // registering multiple accounts.
          localStorage.setItem('locale_set', 'true')
          await context.refreshUser()
          navigate('/')
        }
      } catch (e) {
        console.error(e)
      }
    }

    setLoading(false)
  }

  return (
    <Styled>
      <Typography variant="h5" gutterBottom>{t('log-in', `Log in`)}</Typography>
      <FormControl>
        <TextField required error={!validatedEmail} helperText={validationEmailText} label={t('email', `Email`)}
                   type="email"
                   autoComplete="email"
                   value={email}
                   onChange={event => setEmail(event.target.value)}/>
      </FormControl>
      <FormControl>
        <TextField required error={!validatedPassword} helperText={validationPasswordText}
                   label={t('password', `Password`)}
                   autoComplete="current-password"
                   type="password"
                   value={password}
                   onChange={event => setPassword(event.target.value)}/>
      </FormControl>
      <LoadingButton loading={loading} variant="contained" onClick={onLogin}>{t('login', `Login`)}</LoadingButton>
      {invalidLogin &&
        <Alert severity="error">
          {t('invalid-user-or-pw', `Invalid username or password`)}
        </Alert>}
      <div className="center">
        <Typography variant={'body2'}>
          <Trans>
            New user? Sign up <Link to="/signup">here</Link>
          </Trans>
        </Typography>
        <Typography variant={'body2'}><Link to="/reset-password">{t('forgot-password', `Forgot your password?`)}</Link></Typography>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
  max-width: 500px;
  margin: 50px 16px 0 16px;

  .center {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`

export default LoginComponent

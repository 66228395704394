import React from 'react';
import { useTranslation } from 'react-i18next';

import Restaurant from './Restaurant';

const PlazaGardens = () => {
  const {t} = useTranslation()

  const RESTAURANT_ID = 'P1MR08'
  const content = t('plaza-gardens-description', `Currently, lunch at Plaza Gardens restaurant does not have characters in attendance. It is one of the nicer restaurants in DLP though so if you're not looking for character dining, you'll be hard pressed to find a better place for a nice lunch!\n
  If you're looking for a lunch time reservation, use "Plaza Gardens Restaurant (no characters)" as your restaurant choice.`)

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default PlazaGardens

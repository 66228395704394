import React from 'react';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useTranslation } from 'react-i18next';

import { GRAY_6, INDIGO } from '../styles';

const HowItWorks = () => {
  const {t} = useTranslation()

  return (
    <Styled>
      <Typography variant="h6" gutterBottom>{t('how-it-works', `How it works`)}</Typography>
      <Card className="step-card">
        <div className="card-container">
          <div className={'step-number-container'}>1</div>
          <Typography variant="h6" gutterBottom className="step-title">
            <NotificationAddIcon fontSize="large"/> {t('create-an-alert', `Create an alert`)}
          </Typography>
          <Typography variant="body1">
            {t('how-it-works-1', `Our software will immediately start checking for available tables and send you an
              email or SMS alert as soon as it finds one.`)}
          </Typography>
        </div>
      </Card>
      <Card className="step-card">
        <div className="card-container">
          <div className={'step-number-container'}>2</div>
          <Typography variant="h6" gutterBottom className="step-title">
            <NotificationsActiveIcon
              fontSize="large"/> {t('book-table-in-app', `Book the table using the app or website`)}
          </Typography>
          <Typography variant="body1">
            {t('how-it-works-2', `Once you have been notified a table is available, use the Disneyland Paris app or disneylandparis.com to reserve the table.`)}
          </Typography>
          <Typography variant={'body1'}>
            {t('how-it-works3', `If you missed the table, don't worry, we'll keep checking until you tell us to stop.`)}
          </Typography>
        </div>
      </Card>
    </Styled>
  )
}

const Styled = styled.div`
    max-width: 500px;
    margin-top: 5rem;


    .step-card {
        margin: 1rem 0 2rem 0;
    }

    .card-container {
        margin: 1.5rem;
    }

    .step-title {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .step-number-container {
        position: absolute;
        margin: -2rem 0 0 -2rem;
        padding: 0.5rem;
        background-color: ${INDIGO};
        color: ${GRAY_6};
        border-radius: 100%;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 2px 2px gray;
        font-weight: bold;
    }

`

export default HowItWorks

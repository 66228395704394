import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from './Loading';

type PaymentStatusProps = {
  setPayDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentStatus = ({setPayDialogOpen}: PaymentStatusProps) => {
  const stripe = useStripe();
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [message, setMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      if (paymentIntent) {

        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(t('payment-succeeded', 'Payment succeeded!'));
            setSuccess(true)
            break;
          case 'processing':
            setMessage(t('payment-processing', 'Your payment is processing.'));
            setSuccess(false)
            break;
          case 'requires_payment_method':
            setMessage(t('payment-not successful', 'Your payment was not successful, please try again.'));
            setSuccess(false)
            break;
          default:
            setMessage(t('something-wrong', 'Something went wrong.'));
            setSuccess(false)
            break;
        }
      }
    });
  }, [stripe, t]);

  const onOKClick = () => {
    navigate('/')
    setPayDialogOpen(false)
  }

  return message ? (
    <Styled>
      <DialogTitle>{message}</DialogTitle>
      {success ? (<><Typography
          variant="body1" paragraph>{t('thanks-payment', `Thank you for your payment`)} 🥹.</Typography>
          <Typography
            variant="body1" paragraph>
            {t('robots-looking', `Our robots 🤖 have already started looking for your table and we'll contact you as soon as we find one!`)}
          </Typography></>) :
        <Typography
          variant="body1"
          paragraph>{t('contact-if-error', `Please contact us if you think there is an error.`)}</Typography>}

      <Button variant={'contained'} onClick={onOKClick}>{t('ok', `OK`)}</Button>
    </Styled>
  ) : (<Loading/>)
}

const Styled = styled.div`


`

export default PaymentStatus

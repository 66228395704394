import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
} from 'react-share'
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { MainContext } from './Main';

const Footer = () => {
  const context = useContext(MainContext)
  const {t} = useTranslation()

  return (
    <Styled>
      {context.user && <Typography><Link to={'/alerts'}>{t('your-alerts', `Your Alerts`)}</Link></Typography>}
      <Typography><Link to={'/pricing'}>{t('pricing', `Pricing`)}</Link></Typography>
      <Typography><Link to={'/restaurants'}>{t('restaurant-info', `Restaurant Info`)}</Link></Typography>
      <Typography paragraph><Link to={'/faq'}>{t('faq', `FAQ`)}</Link></Typography>
      <Typography variant="subtitle2" className="share-title">{t('follow', `Follow`)}</Typography>
      <div className="follow-buttons">
        <Button
          onClick={() => window.location.replace('https://www.facebook.com/people/Dlptablescom/100087040598322/')}>
          <FacebookIcon size={42} borderRadius={8}/>
        </Button>
        <Button
          onClick={() => window.location.replace('https://twitter.com/dlptables')}>
          <TwitterIcon size={42} borderRadius={8}/>
        </Button>
      </div>
      <Typography variant="subtitle2" className="share-title">{t('share', `Share`)}</Typography>
      <div className="share-buttons">
        <WhatsappShareButton url="https://dlptables.com"
                             title="I got a restaurant reservation at Disneyland Paris using dlptables.com">
          <WhatsappIcon size={42} borderRadius={8}/>
        </WhatsappShareButton>
        <RedditShareButton url="https://dlptables.com"
                           title="I got a restaurant reservation at Disneyland Paris using dlptables.com">
          <RedditIcon size={42} borderRadius={8}/>
        </RedditShareButton>
        <TelegramShareButton url="https://dlptables.com"
                             title="I got a restaurant reservation at Disneyland Paris using dlptables.com">
          <TelegramIcon size={42} borderRadius={8}/>
        </TelegramShareButton>
      </div>
      <p className="copyright">© 2024 dlptables.com </p>
      <p className="disclaimer">{t('disclaimer', `This website is independent of and not supported, endorsed by or connected to
        Disneyland® Paris, The Walt Disney Company, Euro Disney Associés S.C.A., Disney Enterprises, Inc. or their
        subsidiaries and affiliates.`)}</p>
    </Styled>
  )
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin: 5rem 0;

    .copyright {
        font-size: 0.8rem;
        margin-top: 3rem;
        margin-bottom: 0rem;
    }

    .disclaimer {
        font-style: italic;
        font-size: 0.6rem;
    }

    .follow-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 100px;
        margin-left: -10px;
    }

    .share-title {
        margin-top: 2rem;
    }

    .share-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 170px;
    }
`

export default Footer

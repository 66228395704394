import React from 'react';
import { useTranslation } from 'react-i18next';

import Restaurant from './Restaurant';

const CharacterDiningAtPlazaGardens = () => {
  const {t} = useTranslation()

  const RESTAURANT_ID = 'P1MR12'
  const content = t('character-dining-content', `Character Dining at Plaza Gardens is one of only 2 character dining experiences in Disneyland Paris and the only restaurant where you can eat with Mickey and friends.\n
  The exact characters vary, but during your meal cast members will bring characters to your table. The character will interact with you/your children, pose for photos (the cast member will be happy to take photos with your phone) and you can also get their autograph.\n
  Plaza Gardens restaurant is open for breakfast, lunch and dinner, but NOTE: characters are only present at breakfast and dinner. At lunch there are no characters. `)

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default CharacterDiningAtPlazaGardens

import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { getStripeClientSecret } from '../utils/api';
import Loading from './Loading';
import styled from '@emotion/styled';
import PaymentStatus from './PaymentStatus';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID as string)

const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation()
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true)
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://dlptables.com/',
      },
    });


    if (error) {
      setMessage(error.message as string);
    } else {
      setMessage(t('error-occurred', 'An unexpected error occurred. Please try again or contact us.'))
    }
    setLoading(false)
  };

  return stripe && elements ? (
    <Styled>
      <DialogContentText>
        <Typography variant={'h4'} paragraph>{t('alert-payment', `Alert Payment`)}</Typography>
        <Typography paragraph>{t('alert-payment-1', `1 x priority email alert: €4.00`)}</Typography>
        <Typography className={'bold'}>{t('alert-payment-2', `Your total: €4.00`)}</Typography>
      </DialogContentText>
      <PaymentElement className={'payment-form'}/>
      <LoadingButton loading={loading} variant={'contained'}
                     onClick={handleSubmit}>{t('submit', `Submit`)}</LoadingButton>
      {message && <div className={'payment-message'}>{message}</div>}
    </Styled>
  ) : <Loading/>
}

type PaymentFormProps = {
  setPayDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservationId: string | null;
}
const PaymentForm = ({setPayDialogOpen, reservationId}: PaymentFormProps) => {
  const [clientSecret, setClientSecret] = useState<string>('')
  const [paymentFinished, setPaymentFinished] = useState<boolean>(false)

  useEffect(() => {

    const existingClientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );


    const getStripeSecret = async (reservationId: string) => {
      const response = await getStripeClientSecret(reservationId)
      const data = await response.json()
      setClientSecret(data.client_secret)
    }

    if (existingClientSecret) {
      setClientSecret(existingClientSecret)
      setPaymentFinished(true)
    } else {
      if (reservationId) {
        getStripeSecret(reservationId)
      } else {
        console.error('reservation_id is null')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = {
    clientSecret,
  }


  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      {paymentFinished ? <PaymentStatus setPayDialogOpen={setPayDialogOpen}/> :
        <StripeForm/>}
    </Elements>
  ) : <Loading/>

}

const Styled = styled.div(`
  .payment-form {
    margin: 2rem 0;
  }
  
  .payment-message {
    margin-top: 1rem;
  }
  
  .bold {
    font-weight: bold;
  }
`)

export default PaymentForm

import React from 'react';

import Restaurant from './Restaurant';

const TheSteakhouse = () => {

  const RESTAURANT_ID = 'D01R08'
  const content = ``

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default TheSteakhouse

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import { validatePhone } from '../utils/helpers'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { setPhoneNumber } from '../utils/api';

type phoneDialogProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCheckout: (checkoutType: string) => {}
  PaypalButtons: React.FC<{ subscriptionId: string }>
  MONTHLY_PAYPAL_SMS_SUB_ID: string
  isUpgrade: boolean
}
export default function PhoneDialog({
                                      open,
                                      setOpen,
                                      onCheckout,
                                      PaypalButtons,
                                      MONTHLY_PAYPAL_SMS_SUB_ID,
                                      isUpgrade,
                                    }: phoneDialogProps) {
  const {t} = useTranslation()
  const [phone, setPhone] = useState<string>('')
  const [validatedPhone, setValidatedPhone] = useState<boolean>(true)
  const [phoneSubmitted, setPhoneSubmitted] = useState<boolean>(false)
  const [validationPhoneText, setValidationPhoneText] = useState<string>('')

  const handleClose = () => {
    setOpen(false);
    setPhoneSubmitted(false)
  };

  const onSubmitPhone = async () => {
    if (validatePhone(phone)) {
      const response = await setPhoneNumber(phone)
      if (response.ok) {
        if (isUpgrade) {
          onCheckout('sms-upgrade')
        }
        setValidatedPhone(true)
        setValidationPhoneText('')
        setPhoneSubmitted(true)
      } else {
        setValidatedPhone(false)
        setValidationPhoneText(t('invalid-number', 'Number is invalid. Number should include the country code, start with a + and be followed only by numbers. US numbers are not accepted.') as string)
      }
    } else {
      setValidatedPhone(false)
      setValidationPhoneText(t('invalid-number-2', 'Number should include the country code, start with a + and be followed only by numbers') as string)
    }
  }

  const checkoutClick = (event: React.MouseEvent<HTMLElement>) => {
    onCheckout('sms-subscribe')
  }

  return (
    <div>
      {!phoneSubmitted ? <Dialog open={open} onClose={handleClose}>
          {
            isUpgrade ?
              <DialogTitle>{t('sms-subscription-upgrade', `SMS Subscription Upgrade`)}</DialogTitle> :
              <DialogTitle>{t('sms-subscription', `SMS Subscription`)}</DialogTitle>
          }
          <DialogContent>
            <DialogContentText>
              {
                t('enter-number', `Please enter your mobile phone number for SMS alerts, including the country code. We will send you a confirmation SMS following payment.`)
              }
            </DialogContentText>
            {isUpgrade &&
              <DialogContentText>{t('prorated-charge', 'Your previously used payment method will immediately be charged the prorated extra cost for this month.')}</DialogContentText>}
            <DialogContentText
              sx={{marginTop: '1rem'}}>{t('no-us-numbers', '🇺🇸 NOTE: Due to new US regulations, we CANNOT send SMS alerts to US phone numbers.')}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label={t('phone', 'Phone')}
              type="tel"
              autoComplete="tel"
              placeholder="e.g. +44 787 025 8784"
              fullWidth
              variant="standard"
              value={phone ?? ''}
              onChange={event => setPhone(event.target.value)}
              error={!validatedPhone}
              helperText={validationPhoneText}
            />
          </DialogContent>
          <DialogActions>
            {
              isUpgrade ?
                <Button onClick={onSubmitPhone} variant="contained">{t('pay-now', `Pay now`)}</Button> :
                <Button onClick={onSubmitPhone} variant="contained">{t('continue', `Continue`)}</Button>
            }
          </DialogActions>
        </Dialog>
        :
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t('sms-subscription', `SMS Subscription`)}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('choose-payment', `Please choose your payment method`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{marginRight: '8px', marginTop: '3px'}}>
              <PaypalButtons subscriptionId={MONTHLY_PAYPAL_SMS_SUB_ID}/>
            </div>
            <Button className="subscribe-button" id="sms-subscribe" variant="contained"
                    onClick={checkoutClick}><CreditCardIcon
              sx={{marginRight: '8px'}}/> {t('subscribe', `Subscribe`)}</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}

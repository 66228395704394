import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QuizIcon from '@mui/icons-material/Quiz';
import SellIcon from '@mui/icons-material/Sell';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useTranslation } from 'react-i18next';

import { MainContext } from './Main';

type Props = {
  toggleDrawer: () => void
}
const DrawerContent = ({toggleDrawer}: Props) => {
  const user = useContext(MainContext)
  const {t} = useTranslation()

  return (
    <Box
      sx={{width: 200}}
      role="presentation"
      onClick={toggleDrawer}
    >
      <List>
        <ListItem disablePadding>
          <Link to={'/'} style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon/>
              </ListItemIcon>
              <ListItemText primary={t('home', 'Home')}/>
            </ListItemButton>
          </Link>
        </ListItem>
        {user?.user && <ListItem disablePadding>
          <Link to={'/alerts'} style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
            <ListItemButton>
              <ListItemIcon>
                <FormatListBulletedIcon/>
              </ListItemIcon>
              <ListItemText primary={t('your-alerts', 'Your Alerts')}/>
            </ListItemButton>
          </Link>
        </ListItem>}
        <ListItem disablePadding>
          <Link to={'/pricing'} style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
            <ListItemButton>
              <ListItemIcon>
                <SellIcon/>
              </ListItemIcon>
              <ListItemText primary={t('pricing', 'Pricing')}/>
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to={'/restaurants'} style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
            <ListItemButton>
              <ListItemIcon>
                <RestaurantIcon/>
              </ListItemIcon>
              <ListItemText primary={t('restaurant-info', 'Restaurant Info')}/>
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to={'/faq'} style={{textDecoration: 'none', color: 'inherit', width: '100%'}}>
            <ListItemButton>
              <ListItemIcon>
                <QuizIcon/>
              </ListItemIcon>
              <ListItemText primary={t('faq', 'FAQ')}/>
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  )
}

export default DrawerContent

import React, { useContext, useRef, useState } from 'react';
import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Tooltip from '@mui/material/Tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTranslation } from 'react-i18next';

import logoText from '../images/logo_text_500.jpg';
import { MainContext } from './Main';
import ConfirmationDialog from './ConfirmationDialog';
import { cancelPayPalSubscription, getManageSubscriptionURL, removeToken } from '../utils/api';
import { PURPLE } from '../styles';
import { ISnackbarContent } from '../interfaces';

type Props = {
  onMenuClick: () => void
}
const Header = ({onMenuClick}: Props) => {
  const context = useContext(MainContext)
  const {t, i18n} = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'fr'>(i18n.resolvedLanguage as 'en' | 'fr')
  const [otherLanguage, setOtherLanguage] = useState<'en' | 'fr'>(i18n.resolvedLanguage === 'en' ? 'fr' : 'en' as 'en' | 'fr')
  const [loading, setLoading] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const refProfile = useRef(null)
  const [languagePickerOpen, setLanguagePickerOpen] = useState<boolean>(false)
  const refLanguagePicker = useRef(null)
  const navigate = useNavigate()
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarContent, setSnackbarContent] = useState<ISnackbarContent>({severity: 'success', title: '', body: ''})
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)

  const Profile = () => {

    const handleLogout = () => {
      setMenuOpen(false)
      refProfile.current = null
      removeToken()
      context.refreshUser()
      navigate('/')
    }

    const onManageSubscription = async () => {
      setMenuOpen(false)
      const res = await getManageSubscriptionURL()
      if (res?.url) {
        window.location.replace(res.url)
      }
    }

    const onCancelSubscription = async () => {
      setMenuOpen(false)
      const res = await getManageSubscriptionURL()
      if (res?.url) {
        if (res.url.includes('paypal') && res?.hasPaypalSubId) {
          // It's a PayPal subscription so we cancel it directly, after showing a confirmation dialog
          setShowConfirmationDialog(true)
        } else {
          window.location.replace(res.url)
        }
      }
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }
      setShowSnackbar(false)
    }

    const handleConfirmationDialogCancel = () => {
      setShowConfirmationDialog(false)
    }

    const handleConfirmationDialogOk = async () => {
      // Customer has confirmed they would like to cancel their PayPal subscription
      const cancelRes = await cancelPayPalSubscription()
      const cancelResJSON = await cancelRes.json()
      if (cancelResJSON?.success) {
        setSnackbarContent({
          severity: 'success',
          title: t('subscription-cancelled-success', 'Subscription cancelled'),
          body: t('subscription-cancelled-success-body', 'Your subscription has been cancelled. You can still use your membership until the end of your billing period.'),
        })
        setShowSnackbar(true)
      } else if (cancelResJSON === 'Subscription already cancelled') {
        setSnackbarContent({
          severity: 'success',
          title: t('subscription-already-cancelled-success', 'Subscription already cancelled'),
          body: t('subscription-already-cancelled-success-body', 'Your subscription has already been cancelled. You can still use your membership until the end of your billing period.'),
        })
        setShowSnackbar(true)
      } else {
        setSnackbarContent({
          severity: 'error',
          title: t('subscription-cancelled-error', 'Error cancelling subscription'),
          body: t('subscription-cancelled-error-body', 'Please try again or contact us.'),
        })
        setShowSnackbar(true)
      }
      setShowConfirmationDialog(false)
    }

    return (
      <>
        <Button
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={() => setMenuOpen(true)}
        >
          {context?.user?.activeSubscriber ? <Badge overlap="circular"
                                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                                    badgeContent={<>😎</>}>
              <Tooltip title={context.user.email}>
                <Avatar sx={{bgcolor: PURPLE}}>{context?.user?.email[0].toUpperCase()}</Avatar>
              </Tooltip>
            </Badge> :
            <Avatar sx={{bgcolor: PURPLE}}>{context?.user?.email[0].toUpperCase()}</Avatar>
          }
        </Button>
        <Menu
          anchorEl={refProfile.current}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {context?.user?.activeSubscriber &&
            <>
              <MenuItem onClick={onManageSubscription}>
                <ListItemIcon>
                  <ManageAccountsIcon/>
                </ListItemIcon>
                <ListItemText primary={t('manage-subscription', `Manage Subscription`)}/>
              </MenuItem>
              <MenuItem onClick={onCancelSubscription}>
                <ListItemIcon>
                  <CancelIcon/>
                </ListItemIcon>
                <ListItemText primary={t('cancel-subscription', `Cancel Subscription`)}/>
              </MenuItem>
            </>
          }
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText>
              {t('log-out', `Log out`)}
            </ListItemText>
          </MenuItem>
        </Menu>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarContent.severity} sx={{width: '100%'}}>
            <AlertTitle>{snackbarContent.title}</AlertTitle>
            {snackbarContent.body}
          </Alert>
        </Snackbar>
        <ConfirmationDialog open={showConfirmationDialog} setOpen={setShowConfirmationDialog}
                            dialogTitle={t('are-you-sure', 'Are you sure?')}
                            dialogText={t('cancel-dialog-text', 'Are you sure you wish to cancel your membership?')}
                            cancelButtonText={t('no', 'No')}
                            okButtonText={t('yes', 'Yes')}
                            handleCancelClick={handleConfirmationDialogCancel}
                            handleOkClick={handleConfirmationDialogOk}
        />
      </>
    )
  }

  const LanguagePicker = () => {

    const changeLanguage = async (language: 'en' | 'fr') => {
      setLoading(true)
      setLanguagePickerOpen(false)
      if (language === 'fr') {
        window.location.replace(process.env.REACT_APP_FRONTEND_URL + '/fr')
      } else {
        window.location.replace(process.env.REACT_APP_FRONTEND_URL as string)
      }
      setCurrentLanguage(language)
      setOtherLanguage(language === 'en' ? 'fr' : 'en')
      setLoading(false)
    }


    return (<>
      <LoadingButton
        aria-controls={languagePickerOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={languagePickerOpen ? 'true' : undefined}
        onClick={() => setLanguagePickerOpen(true)}
        loading={loading}
      >
        {currentLanguage}
      </LoadingButton>
      <Menu
        anchorEl={refLanguagePicker.current}
        open={languagePickerOpen}
        onClose={() => setLanguagePickerOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => changeLanguage(otherLanguage)}>
          <ListItemText primary={otherLanguage.toUpperCase()}/>
        </MenuItem>
      </Menu>
    </>)
  }

  return (
    <Styled>
      <Box sx={{flexGrow: 1}}>
        <AppBar color="inherit">
          <Toolbar>
            <Button onClick={onMenuClick}>
              <MenuIcon sx={{color: 'black'}}/>
            </Button>
            <div className="header-logo">
              <Link to={'/'}>
                <img className={'logo-text'} src={logoText} alt={'dlpTables'}/>
              </Link>
            </div>
            <div ref={refLanguagePicker}>
              <LanguagePicker/>
            </div>
            {!context?.user ? <Link to={'/login'} className="link-no-underline">
              <Button className="login-btn" variant="text">{t('log-in', `Log in`)}</Button>
            </Link> : <div ref={refProfile}><Profile/></div>}
          </Toolbar>
        </AppBar>
      </Box>
    </Styled>
  )
}

const Styled = styled.div`

    .header-logo {
        flex-grow: 1;

        .logo-text {
            margin-top: 10px;
            width: 100%;
            max-width: 200px;
        }
    }

    .link-no-underline {
        text-decoration: none;
    }
`

export default Header

import React from 'react';

import Restaurant from './Restaurant';

const PYMKitchen = () => {

  const RESTAURANT_ID = 'P2AR02'
  const content = ``

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default PYMKitchen

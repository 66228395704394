import React from 'react';
import { useTranslation } from 'react-i18next';

import Restaurant from './Restaurant';

const AubergeDeCendrillon = () => {
  const {t} = useTranslation()

  const RESTAURANT_ID = 'P1NR01'
  const content = t('auberge-content', `Auberge de Cendrillon is one of only 2 character dining experiences in Disneyland Paris and the only restaurant where you can dine with Disney Princesses.\n
  The exact princesses vary, but during your meal cast members will bring the princesses to your table. The character will interact with you/your children, pose for photos (the cast member will be happy to take photos with your phone) and you can also get their autograph.\n
  Auberge de Cendrillon is the most expensive restaurant in Disneyland Paris. While the food is good, keep in mind that you are paying for the experience, not for fine food, and you won't be disappointed 😀\n
  Auberge is open daily for lunch and dinner.`)

  return (
    <Restaurant restaurantId={RESTAURANT_ID} content={content}/>
  )
}

export default AubergeDeCendrillon

import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { FormControl, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { login, signUp } from '../utils/api';
import { MainContext } from './Main';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';

const SignUpComponent = () => {
  const context = useContext(MainContext)
  const {t, i18n} = useTranslation()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true)
  const [validationEmailText, setValidationEmailText] = useState<string>('')
  const [validatedPassword, setValidatedPassword] = useState<boolean>(true)
  const [validationPasswordText, setValidationPasswordText] = useState<string>(t('min-7-chars', 'Minimum 7 characters') as string)
  const [validatedConfirmPassword, setValidatedConfirmPassword] = useState<boolean>(true)
  const [validationConfirmPasswordText, setValidationConfirmPasswordText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate();

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onSignUp = async () => {
    setLoading(true)
    setValidatedEmail(true)
    setValidationEmailText('')
    setValidatedPassword(true)
    setValidationPasswordText(t('min-7-chars', 'Minimum 7 characters') as string)
    setValidatedConfirmPassword(true)
    setValidationConfirmPasswordText('')

    if (password.length < 7) {
      setValidatedPassword(false)
      setValidationPasswordText(t('min-7-chars', 'Minimum 7 characters') as string)
    } else if (password !== confirmPassword) {
      setValidatedConfirmPassword(false)
      setValidationConfirmPasswordText(t('passwords-no-match', 'Passwords do not match') as string)
    }

    if (!validateEmail(email)) {
      setValidatedEmail(false)
      setValidationEmailText(t('valid-email-required', 'Valid email required') as string)
    }

    // Check if this device has signed up with a different user
    if (localStorage.getItem('locale_set') === 'true') {
      setValidatedEmail(false)
      setValidationEmailText(t('already-registered', 'It looks like you have already registered with a different email address. Please consider becoming a member.') as string)
    }

    if (validatedEmail) {
      const language = i18n.resolvedLanguage

      try {
        const signUpResponse = await signUp(
          email,
          password,
          language,
        )
        if (!signUpResponse.ok) {
          throw new Error(await signUpResponse.json())
        }
      } catch (e: any) {
        setValidatedPassword(false)
        setValidatedConfirmPassword(false)
        setValidationPasswordText(e.message)
        setLoading(false)
        return false
      }

      try {
        const response = await login(
          email,
          password,
        )
        const data = await response.json()
        localStorage.setItem('dlp_jwt', data.access)
        // This is our flag to indicate devices that have already signed up. We check it to avoid the same device
        // registering multiple accounts.
        localStorage.setItem('locale_set', 'true')
        await context.refreshUser()
        navigate('/')
      } catch (e) {
        console.error(e)
      }
    }

    setLoading(false)
  }

  return (
    <Styled>
      <Typography variant="h5" gutterBottom>{t('sign-up', 'Sign up')}</Typography>
      <FormControl>
        <TextField required error={!validatedEmail} helperText={validationEmailText} label={t('email', 'Email')}
                   type="email"
                   autoComplete="email"
                   value={email}
                   onChange={event => setEmail(event.target.value)}/>
      </FormControl>
      <FormControl>
        <TextField required error={!validatedPassword} helperText={validationPasswordText} label={t('pw', 'Password')}
                   type="password"
                   autoComplete="new-password"
                   value={password}
                   onChange={event => setPassword(event.target.value)}/>
      </FormControl>
      <FormControl>
        <TextField required error={!validatedConfirmPassword} helperText={validationConfirmPasswordText}
                   label={t('confirm-password', 'Confirm Password')}
                   type="password"
                   autoComplete="new-password"
                   value={confirmPassword}
                   onChange={event => setConfirmPassword(event.target.value)}/>
      </FormControl>
      <LoadingButton loading={loading} variant="contained" onClick={onSignUp}>{t('sign-up', 'Sign up')}</LoadingButton>
      <div className="center">
        <Typography variant={'body2'}>
          <Trans>
            Have an account? Log in <Link
            to="/login">here</Link>
          </Trans>
        </Typography>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    row-gap: 12px;
    max-width: 500px;
    margin: 50px 16px 0 16px;

    .center {
        display: flex;
        justify-content: center;
    }
`

export default SignUpComponent
